<template>
    <v-row>
        <v-col cols="12" class="d-flex align-center pb-0">
            <my-mini-avatar :user="user" />
            <v-text-field dense color="rgb(224, 166, 119)" v-model="commentField" @keyup.enter="newComment"
                class="ml-3 pr-4" placeholder="Напишите комментарий..." append-icon="mdi-attachment"
                append-outer-icon="mdi-keyboard-return" @click:append="attachmentFile" @click:append-outer="newComment" />
        </v-col>
        <div style="display: none;" ref="file"></div>
        <v-col cols="12" class="py-0">
            <v-container fluid class="pa-0">
                <v-row>
                    <v-col v-for="(image, index_image) in filesCommentField" :key="index_image" class="d-flex child-flex"
                        cols="3">
                        <my-preview-image :fbPath="image" @delete="deleteImage" />
                    </v-col>
                </v-row>
            </v-container>
        </v-col>
    </v-row>
</template>

<script>
import imageCompression from 'browser-image-compression'
import loadImage from 'blueimp-load-image';
import api from '@/utils/api'
const fileUrl = process.env.VUE_APP_FILE_URL
import { mapActions } from 'vuex'
export default {
    props: ['item_id', 'item'],
    data: () => ({
        commentField: '',
        filesCommentField: [],
        compressedFile: null,
        files: null,
    }),
    methods: {
        ...mapActions('notify', ['notify']),
        async deleteImage(path) {
            let fileIndex = this.filesCommentField.findIndex(x => x === path)
            if (fileIndex != -1) {
                // let delFile = await this.$delFbFile(path)
                // if (delFile) {
                this.filesCommentField.splice(fileIndex, 1)
                // }
            }
        },
        async attachmentFile() {
            const input = document.createElement("input")
            input.type = "file";
            input.accept = 'image/*'
            input.multiple = 'multiple'

            // const options = {
            //   maxSizeMB: 3,
            //   maxWidthOrHeight: 1520,
            //   useWebWorker: true
            // }

            // input.oninput = async (e) => {
            //     var files = await e.target.files
            //     this.files = files
            //     for(let key in files) {
            //       let file = files[key]
            //       if (file && file instanceof File) {
            //         let compressedFile = await imageCompression(file, options)
            //         this.compressedFile = compressedFile
            //         // let filePath = `task/comment/${new Date().getTime()}_${key}.${/[^.]+$/.exec(file.name)}`
            //         let filePath = await api.upload('upload', compressedFile).then(response => {
            //           if(response.status == 200){
            //             return `${fileUrl}/${response.data.url}`
            //           }
            //         })

            //         this.filesCommentField.push({
            //           uploading: true,
            //           filePath: filePath
            //         })

            //         // let ref = this.$storage.ref().child(filePath).put(compressedFile)
            //         // ref.on(`state_changed`, snapshot => {}, error => {
            //         //   console.log(error)
            //         // }, () => {

            //         let fileIndex = this.filesCommentField.findIndex(x => x.uploading && x.filePath === filePath)
            //         if (fileIndex != -1) {
            //           this.$set(this.filesCommentField, fileIndex, filePath)
            //         }

            //         // })
            //       }
            //     }
            // };

            const compressOptions = {
                maxSizeMB: 3,
                maxWidthOrHeight: 1520,
                useWebWorker: true,
            };

            input.onchange = async (e) => {
                const _this = this;
                var files = await e.target.files;
                this.files = files;

                for (let key in files) {
                    let file = files[key];

                    if (file && file instanceof File) {
                        loadImage.parseMetaData(file, function (data) {
                            const options = {};
                            // Get the correct orientation setting from the EXIF Data
                            if (data.exif) {
                                options.orientation = data.exif.get('Orientation');
                            }

                            // Load the image from disk and inject it into the DOM with the correct orientation
                            loadImage(
                                file,
                                function (canvas) {
                                    let trueCanvas = canvas;
                                    if (canvas instanceof HTMLImageElement) {
                                        const image = canvas;
                                        trueCanvas = document.createElement('canvas');
                                        trueCanvas.width = image.width;
                                        trueCanvas.height = image.height;
                                        trueCanvas.getContext('2d').drawImage(image, 0, 0);
                                    }

                                    trueCanvas.toBlob(async function (trueFile) {
                                        let compressedFile = await imageCompression(trueFile, compressOptions);
                                        _this.compressedFile = compressedFile;

                                        _this.uploadingImage = true;
                                        let filePath = await api.upload('upload', compressedFile).then((response) => {
                                            if (response.status == 200) {
                                                _this.uploadingImage = false;
                                                return `${fileUrl}/${response.data.url}`;
                                            }
                                        });
                                        _this.filesCommentField.push({
                                            uploading: true,
                                            filePath: filePath,
                                        });
                                        let fileIndex = _this.filesCommentField.findIndex(
                                            (x) => x.uploading && x.filePath === filePath,
                                        );
                                        if (fileIndex != -1) {
                                            _this.$set(_this.filesCommentField, fileIndex, filePath);
                                        }
                                    });
                                },
                                options,
                            );
                        });
                    }
                }
            };

            this.$refs.file.appendChild(input)
            input.click();
        },

        async newComment() {
            let obj = {
                text: this.commentField,
                files: String(this.filesCommentField),
                date: new Date(),
                from_id: this.user.id,
                manufacturer_id: this.item_id,
                program: false,
                response_to: null

            }

            // await manufactoryCollection.doc(this.item_id).collection('comments').add(obj)
            await api.post('comments/create', obj).then(response => {
                if (response.status == 200 && response.data.status) {
                    let comment = response.data.comments
                    comment.files = comment.files ? comment.files.split(',') : []
                    this.$store.commit('comments/ADD_COMMENT', comment)
                    this.$emit('load', comment)
                    this.sendNotif(this.commentField)
                }
            })

            this.commentField = ''
            this.filesCommentField = []
        },

        async sendNotif(comment) {
            console.log(this.item.manager)
            let manager = this.item.manager.id
            let prodDirs = this.$store.getters['users/getProdDirs'].map(({ id }) => id)
            let whoTo = [manager, ...prodDirs, this.item.montagers]
            let indexIID = whoTo.findIndex(x => x === this.user.id)
            if (indexIID != -1) {
                whoTo.splice(indexIID, 1)
            }
            console.log(whoTo, 'whoTo')
            let title = `${this.user.displayName} написал комментарий`
            let text = `Производство ${this.item.client.name}: '${comment}'`

            if (whoTo && whoTo.length > 0) {
                for (let to of whoTo) {
                    await this.notify({
                        to,
                        title: title,
                        text: text,
                        action: 'newCommentToManufactory',
                        payload: {
                            taskId: this.item_id
                        }
                    })
                }
            }
        }
    },
    computed: {
        user() {
            return this.$store.getters['user/getUser']
        }
    }
}
</script>
