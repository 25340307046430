<template>
  <div class="mobTasks">
    <div class="mobTasks_container">
      <v-expansion-panels v-if="tasks && tasks.length > 0">
        <v-expansion-panel
          v-for="task in tasks"
          :key="task.id"
          class="mobTasks_container_task"
        >
          <v-expansion-panel-header
            :style="task.status === 'ready' ? 'border-left: 6px solid green;' : 'border-left: 6px solid white;'"
          >
            <v-row class="pa-0 ma-0">
              <v-col cols="12" class="pa-0 py-1 ma-0">
                <span style="font-weight: 500;">Дата: </span>
                {{ $formatDate(new Date(task.date)) }}
              </v-col>
              <v-col cols="12" class="pa-0 py-1 ma-0">
                <span style="font-weight: 500;">Клиент: </span>
                {{task.client.name}}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Task :task="task" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div v-else class="mobTasks_container_noTasks">
        Нет заданий
      </div>
    </div>
  </div>
</template>

<script>
import Task from './Task.vue'

export default {
  name: 'MasterTasksMobList',
  components: {
    Task
  },
  props: {
    tasks: {
      type: Array,
      default: []
    }
  },

  data() {
    return { 
      open: {}
    }
  },
}
</script>

<style lang="scss">
.mobTasks {
  &_container {
    border-radius: 0 !important;
    
    &_task {
      border-radius: 0 !important;
      position: relative;

      .v-expansion-panel-content__wrap {
        padding: 0 !important;
      } 


      &_status {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;

        div {
          width: 100%;
          height: 100%;
        }
      }
    }

    &_noTasks {
      display: flex;
      justify-content: center;
      padding-top: 60%;
      color: grey;
      text-transform: uppercase;
    }
  }
}
</style>
