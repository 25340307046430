import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobTasks"},[_c('div',{staticClass:"mobTasks_container"},[(_vm.tasks && _vm.tasks.length > 0)?_c(VExpansionPanels,_vm._l((_vm.tasks),function(task){return _c(VExpansionPanel,{key:task.id,staticClass:"mobTasks_container_task"},[_c(VExpansionPanelHeader,{style:(task.status === 'ready' ? 'border-left: 6px solid green;' : 'border-left: 6px solid white;')},[_c(VRow,{staticClass:"pa-0 ma-0"},[_c(VCol,{staticClass:"pa-0 py-1 ma-0",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-weight":"500"}},[_vm._v("Дата: ")]),_vm._v(" "+_vm._s(_vm.$formatDate(new Date(task.date)))+" ")]),_c(VCol,{staticClass:"pa-0 py-1 ma-0",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-weight":"500"}},[_vm._v("Клиент: ")]),_vm._v(" "+_vm._s(task.client.name)+" ")])],1)],1),_c(VExpansionPanelContent,[_c('Task',{attrs:{"task":task}})],1)],1)}),1):_c('div',{staticClass:"mobTasks_container_noTasks"},[_vm._v(" Нет заданий ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }