<template>
  <div>
    <v-card-text v-if="task.id" class="grey lighten-3">
      <v-container grid-list-md>
        <v-layout wrap>
            <v-flex xs12 v-if="task.comment.length != 0">
              <span class="name_field">Комментарий:</span>
              <p class="content_task pl-2">{{task.comment}}</p>
            </v-flex>
            <!-- <v-flex xs12 v-if="task.commentProd.length != 0">
              <span class="name_field">Комментарий производства:</span>
              <p class="content_task pl-2">{{task.commentProd}}</p>
            </v-flex> -->

            <v-flex xs12>
              <span class="name_field">Менеджер:</span> {{task.manager.displayName}}
            </v-flex>
            <v-flex xs12 v-if="!getHourPayWorkMasters">
              <span class="name_field">Стоимость:</span>
              <span v-if="task.costForProd">
                {{task.costForProd}}р.
              </span>
              <span v-else>
                Не указана
              </span>
            </v-flex>
            
            <v-flex xs12 v-if="task.montagers && (task.montagersInfo && Object.keys(task.montagersInfo).length > 0)">
              <span class="name_field">Исполнители:</span>
              <span 
                v-for="(montager, index_montager) in task.montagersInfo"
                :key="index_montager"
              >
                <span :style="montager.boss ? 'font-weight: 500;' : ''">
                  {{ montager.name }}
                </span>
                <!-- <span v-if="!getHourPayWorkMasters && user.role != 'manager'">({{ montager.costWork }}р.)</span> -->
                <span v-if="index_montager != Object.keys(task.montagersInfo)[Object.keys(task.montagersInfo).length - 1]">,</span>
              </span>
            </v-flex>

            <v-flex xs12 v-if="task.readyDate">
              <span class="name_field">Готово:</span>
              <span> {{ $formatDateTime(new Date(task.readyDate)) }}</span>
            </v-flex>
            
            <v-flex xs12 v-if="task.archiveDate">
              <span class="name_field">Архив:</span>
              <span> {{ $formatDateTime(new Date(task.archiveDate)) }}</span>
            </v-flex>
        </v-layout>
      </v-container>

      <v-expansion-panels v-if="task.images && task.images.length > 0">
        <v-expansion-panel>
          <v-expansion-panel-header>
            Фото
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-carousel
              v-model="activImg"
              :show-arrows="task.images.length > 1"
              height="280"
              style="position: relative;"
              hide-delimiters
            >
              <template v-for="(item,i) in task.images" >
                <div
                  :key="`full_${i}`"
                  style="position: absolute; top: 0px; right: 10px; z-index: 1;"
                  class="d-flex justify-end pa-1"
                >
                  <v-icon @click="$showImageMob(images[activImg], images)" size="40" color="red">mdi-fullscreen</v-icon>
                </div>
                <v-carousel-item :key="i">
                    <template v-slot:default>
                      <div style="height: 100%;" class="d-flex align-center justify-center">
                        <v-img
                          :src="images[i]"
                          max-height="280"
                          class="grey lighten-2"
                          contain
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>
                    </template>
                  </v-carousel-item>
              </template>
            </v-carousel>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
			<Comments
        ref="comments"
				:item_id="task.id"
        :item="task"
				:showProgramComment="showProgramComments"
			/>
    </v-card-text>
    <v-card-actions>
      <v-btn text color="orange" v-if="taskBoss && !['ready', 'archive'].includes(task.status)" @click="assistant(task)">Помошники</v-btn>
      <v-spacer></v-spacer>
      <v-btn text color="blue" v-if="taskBoss && task.status === 'work'" @click="updateStatus(task, 'produced')">В работу</v-btn>
      <v-btn text color="green" v-if="taskBoss && task.status === 'produced'" @click="updateStatus(task, 'ready')">Готово</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import SelectAssistantForMaster from '@/components/Modals/SelectAssistantForMaster'
import Comments from './Comments/Index.vue'

export default {
	components: {
		Comments
	},
  props: {
    task: {
      type: Object,
      default: {}
    }
  },
  data: () => ({
    images: [],
    activImg: 0,
		showProgramComments: false
  }),
  watch: {
    task: {
      deep: true,
      immediate: true,
      async handler(newValue) {
        // this.$refs.comments.loadComments()
        if (newValue.images.length > this.images.length) {
          // let images = []
          // if (this.task.images && this.task.images.length > 0) {
          //   for (let key in this.task.images) {
          //     // let imgUrl = await this.$getFbFileUrl(this.task.images[key])
          //     images.push(this.task.images[key])
          //   }
          // }
          this.images = newValue.images
        }
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/getUser']
    },
    taskBoss() {
      let task = { ...this.task }

      if (task.montagersInfo && task.montagersInfo[this.user.id] && task.montagersInfo[this.user.id].boss) {
        return true
      }

      return false
    },
    getHourPayWorkMasters() {
      return this.$store.getters['settings/getHourPayWorkMasters']
    }
  },
  methods: {
    
    async updateStatus(task, status) {
      let obj = {
        id: task.id,
        data: {
          status: status
        }
      }

      if (this.user.role === 'master' && ['produced'].includes(status)) {
        obj.pushTo = `tasks${status[0].toUpperCase() + status.slice(1)}ForMaster`
        obj.removeFrom = `tasks${task.status[0].toUpperCase() + task.status.slice(1)}ForMaster`
      }

      if (this.user.role === 'master' && status === 'ready') {
        obj.data.readyDate = new Date()
        obj.updState = `tasks${task.status[0].toUpperCase() + task.status.slice(1)}ForMaster`
      }

      await this.$store.dispatch('manufactory/updateTask', obj)
     
      
    },
    async assistant(task) {
      let select = await this.$showModal({
        component: SelectAssistantForMaster,
        isPersistent: true,
        props: {
          task: task,
          mob: true
        }
      })

      if (select) {
        let obj = {
          id: task.id,
          data: {
            ...select
          }
        }

        if (this.user.role === 'master') {
          obj.updState = `tasks${task.status[0].toUpperCase() + task.status.slice(1)}ForMaster`
        }

        this.$store.dispatch('manufactory/updateTask', obj)
      }
    }
  }
}
</script>
